/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../types';

import * as TypescriptOperationsGenerated from "graphql/typescript-operations.generated"
import * as Operations from 'graphql/document-nodes.generated';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from 'withApollo';
export async function getServerPageGetSupportFaq
    (options: Omit<Apollo.QueryOptions<TypescriptOperationsGenerated.GetSupportFaqQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<TypescriptOperationsGenerated.GetSupportFaqQuery>({ ...options, query: Operations.GetSupportFaqDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSupportFaq = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetSupportFaqQuery, TypescriptOperationsGenerated.GetSupportFaqQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSupportFaqDocument, options);
};
export type PageGetSupportFaqComp = React.FC<{data?: TypescriptOperationsGenerated.GetSupportFaqQuery, error?: Apollo.ApolloError}>;
export const withPageGetSupportFaq = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetSupportFaqQuery, TypescriptOperationsGenerated.GetSupportFaqQueryVariables>) => (WrappedComponent:PageGetSupportFaqComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetSupportFaqDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetSupportFaq = {
      getServerPage: getServerPageGetSupportFaq,
      withPage: withPageGetSupportFaq,
      usePage: useGetSupportFaq,
    }