/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import {EntryQueryHandlerMap, SectionQueryHandlerMap} from 'graphql/operations';
import {cloneDeep, get} from 'lodash';
import {GetServerSidePropsContext} from 'next';
import ErrorPage from 'next/error';
import {useRouter} from 'next/router';
import React, {ReactNode} from 'react';
import {BlockRenderer} from '~/components/renderer/block-renderer';
import PageWrapper from '~/components/wrapper/molecules/page-wrapper';
import {DATA_PAGE_PATHS, MAPPING_LOCALES} from '~/constants';
import {GAME_LISTING_SOURCE, NEWS_LISTING_SOURCE} from '~/constants/content-map-type';
import {ssrGetBySlug} from '~/graphql/operations/page/page-query.generated';
import {usePageSetting} from '~/providers/page-context.provider';
import {dateArithmetic, handleConvertToLocaleContentful, handleTranslateNews} from '~/utils';
// import {handleFormatDataMastheadPrices} from '~/utils/button-functions';
import * as Types from '../../graphql/types';
import getPriceGame from '../games/games-graphql';
import getListGameNoReleaseDate from './get-list-game_no_release_date';
import getListNewsGame from './get-list-news-games';
import getSEAFacebookPost from './get-sea-facebook';

const preview = !!(process && process.env && process.env.NEXT_PUBLIC_PREVIEW);

export type getPageOptions = {
  slug: string;
  preview: boolean;
};

export type IItemSupport = {
  __typename: string;
  type?: string;
  title: boolean;
  richTextDescription: string;
  configuration?: any;
  questionsCollection: {
    items: IItemQuestion[];
  };
};

export type IItemQuestion = {
  __typename: string;
  question: string;
  answer: ReactNode | any;
};

export type IItemBreadcrumb = {
  href: string;
};

export type PageProps = {
  locale: string;
  bcProductReference: any;
  data: any;
  error: any;
  listNewsGame: Types.NewsCollection;
  listTagsColor: any;
  listFacebookPost: any;
  listComingSoonGames: any;
  dataTranslation: any;
  host: any;
};

const Page: React.FC<PageProps> = ({
  data,
  bcProductReference = {},
  listNewsGame,
  listComingSoonGames,
  locale,
  listFacebookPost,
  host,
}) => {
  const router = useRouter();
  const page = data?.pageCollection?.items[0];
  const {globalData} = usePageSetting();
  const dataTranslation = globalData?.dataTranslation?.[0] ?? {};
  const listTagsColor = globalData?.listTagsColor ?? [];

  if (!page) {
    return <ErrorPage statusCode={404} />;
  }

  const block: Array<any> = [page.sectionCollection?.items, page.seoStandard].filter(
    block => !!block
  );
  const blockCopy = cloneDeep(block);
  blockCopy[0]?.map((section: any, idx: number) => {
    if (blockCopy[0][idx].blocksCollection?.items[0]) {
      blockCopy[0][idx].blocksCollection.items[0] = {
        ...blockCopy[0][idx].blocksCollection.items[0],
        dataTranslation: dataTranslation,
      };
    }
    if (section?.blocksCollection?.items[0]?.__typename === 'Mashthead') {
      if (bcProductReference && section.blocksCollection.items[0].content) {
        section.blocksCollection.items[0].content.bcProductReference = bcProductReference;
      }
    }

    if (section?.blocksCollection?.items[0]?.dataSource === 'Latest news & events') {
      section.blocksCollection.items[0].__typename = 'HomePageGamesListing';
      section.blocksCollection.items[0].listItemCollection = handleTranslateNews(
        listNewsGame.items,
        dataTranslation
      );
      section.blocksCollection.items[0].listTags = listTagsColor;
      section.blocksCollection.items[0].removePadding = !listComingSoonGames.length ? true : false;
    }

    if (idx === 6) {
      section.blocksCollection.items[0].__typename = 'HomePageNewListing';
      section.blocksCollection.items[0].listTags = listTagsColor;
      section.blocksCollection.items[0].host = host;
    }
    if (section?.blocksCollection?.items[0]?.dataSource === NEWS_LISTING_SOURCE.seaFacebook) {
      section.blocksCollection.items[0].listItemCollection.items = listFacebookPost;
    }

    if (section?.blocksCollection?.items[0]?.dataSource === GAME_LISTING_SOURCE.comingSoonGames) {
      section.blocksCollection.items[0].listItemCollection.items = listComingSoonGames;
    }
  });

  return (
    <PageWrapper theme={page.theme as any}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <BlockRenderer block={[...blockCopy]} />
      </Box>
    </PageWrapper>
  );
};

export type GetPageDataResult = {
  data: PageProps['data'];
  error: PageProps['error'];
};

export async function getPageData(
  options: getPageOptions,
  ctx: GetServerSidePropsContext
): Promise<GetPageDataResult> {
  const {
    props: {data, error},
  } = await ssrGetBySlug.getServerPage(
    {
      variables: {
        ...options,
        locale: handleConvertToLocaleContentful((ctx.locale as string) ?? ''),
      },
    },
    {ssrContext: ctx}
  );
  const result = cloneDeep({data, error});
  if (data.pageCollection?.items[0]) {
    const page = data.pageCollection?.items[0];
    if (page) {
      const queries: Array<ReturnType<typeof SectionQueryHandlerMap['handler']>> = [];

      (page.sectionCollection?.items || []).forEach(item =>
        queries.push(
          SectionQueryHandlerMap['handler'](
            {
              variables: {
                id: item?.sys.id as string,
                locale: handleConvertToLocaleContentful((ctx.locale as string) ?? ''),
              },
            },
            {ssrContext: ctx}
          ).then(async result => {
            result = cloneDeep(result);
            const {
              props: {data},
            } = result;

            let blocks = await Promise.all(
              (data.section?.blocksCollection?.items || []).map(async block => {
                const handlerMap = EntryQueryHandlerMap.find(
                  item => item.__typename === block?.__typename
                );

                if (handlerMap) {
                  const {
                    props: {data},
                  } = await handlerMap.handler(
                    {
                      variables: {
                        id: get(block, 'sys.id') as string,
                        locale: handleConvertToLocaleContentful((ctx.locale as string) ?? ''),
                      },
                    },
                    {ssrContext: ctx}
                  );

                  if (block?.__typename) {
                    return Object.values(data)[0];
                  }
                }
              })
            );
            blocks = blocks.filter(b => !!b);

            if (data.section?.blocksCollection?.items) {
              data.section.blocksCollection.items = [];
              data.section.blocksCollection.items.push(
                ...(blocks as typeof data.section.blocksCollection.items)
              );
            }
            return result;
          })
        )
      );
      const sections = await Promise.all(queries);
      if (result.data.pageCollection?.items[0]?.sectionCollection?.items) {
        result.data.pageCollection.items[0].sectionCollection.items = [];
      }

      sections.forEach(section => {
        const {
          props: {data},
        } = section;
        //@ts-ignore
        result.data.pageCollection?.items[0]?.sectionCollection?.items.push(data['section']);
      });
    }
  }

  return result;
}

export async function getStaticPaths() {
  type SlugParam = {
    params: {
      slug: string[];
      locale?: string;
    };
  };

  const returnSlugs: SlugParam[] = [];
  for (let i = 0; i < MAPPING_LOCALES.length; i++) {
    for (let y = 0; y < DATA_PAGE_PATHS.length; y++) {
      const param: SlugParam = {
        params: {
          slug: [DATA_PAGE_PATHS[y]],
          locale: MAPPING_LOCALES[i].value,
        },
      };
      returnSlugs.push(param);
    }
  }
  return {paths: returnSlugs, fallback: 'blocking'};
}

export const getStaticProps = async (ctx: GetServerSidePropsContext) => {
  const today = new Date();
  const slug = String(ctx?.params?.slug ?? '/');
  const CONTENTFUL_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
  const options: getPageOptions = {
    slug,
    preview,
  };

  const promises: [
    ReturnType<typeof getListNewsGame>,
    ReturnType<typeof getListGameNoReleaseDate>,
    ReturnType<typeof getSEAFacebookPost>
  ] = [
    getListNewsGame(handleConvertToLocaleContentful(ctx.locale as string)),
    getListGameNoReleaseDate(
      handleConvertToLocaleContentful((ctx.locale as string) ?? ''),
      0,
      8,
      {},
      Types.GameOrder.ReleaseDateDesc,
      {
        from: today.toISOString(),
        to: dateArithmetic(today, 6).toISOString(),
      }
    ),
    getSEAFacebookPost(handleConvertToLocaleContentful(ctx.locale as string)),
  ];

  const [listNewsGame, listComingSoonGames, listFacebookPost] = await Promise.all(promises);
  const locale = handleConvertToLocaleContentful(ctx.locale as string) ?? '';
  const {data, error} = await getPageData(options, ctx);

  const mashThead =
    data?.pageCollection?.items?.[0]?.sectionCollection?.items?.[0]?.blocksCollection?.items?.[0]
      ?.content?.bcProductReference ?? '';
  const currencyCode = ['tw-tc', 'tw-sc'].includes(`${ctx?.locale}`.toLowerCase())
    ? 'TWD'
    : ['hk-tc', 'hk-sc'].includes(`${ctx?.locale}`.toLowerCase())
    ? 'HKD'
    : ['ko-kr'].includes(`${ctx?.locale}`.toLowerCase())
    ? 'KRW'
    : 'SGD';

  const bcProductReference = mashThead
    ? await getPriceGame(
        mashThead,
        handleConvertToLocaleContentful(ctx.locale as string),
        currencyCode
      )
    : {};
  const page = data?.pageCollection?.items[0];
  if (!page) {
    return {
      redirect: {
        destination: `/${ctx.locale}/404`,
        permanent: false,
      },
    };
  } else {
    return {
      props: {
        locale,
        bcProductReference,
        data,
        error,
        listNewsGame,
        listComingSoonGames: listComingSoonGames?.items ?? [],
        listFacebookPost,
        host: CONTENTFUL_SITE_URL,
      },
      revalidate: 60,
    };
  }
};

export default Page;
