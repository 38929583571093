/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../types';

import * as TypescriptOperationsGenerated from "graphql/typescript-operations.generated"
import * as Operations from 'graphql/document-nodes.generated';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from 'withApollo';
export async function getServerPageGetSaleListingById
    (options: Omit<Apollo.QueryOptions<TypescriptOperationsGenerated.GetSaleListingByIdQueryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<TypescriptOperationsGenerated.GetSaleListingByIdQueryQuery>({ ...options, query: Operations.GetSaleListingByIdQueryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSaleListingById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetSaleListingByIdQueryQuery, TypescriptOperationsGenerated.GetSaleListingByIdQueryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSaleListingByIdQueryDocument, options);
};
export type PageGetSaleListingByIdComp = React.FC<{data?: TypescriptOperationsGenerated.GetSaleListingByIdQueryQuery, error?: Apollo.ApolloError}>;
export const withPageGetSaleListingById = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetSaleListingByIdQueryQuery, TypescriptOperationsGenerated.GetSaleListingByIdQueryQueryVariables>) => (WrappedComponent:PageGetSaleListingByIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetSaleListingByIdQueryDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetSaleListingById = {
      getServerPage: getServerPageGetSaleListingById,
      withPage: withPageGetSaleListingById,
      usePage: useGetSaleListingById,
    }