/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {THEME} from '~/constants/content-map-type';
import {usePageSetting} from '~/providers/page-context.provider';

interface IPageWrapper {
  children: React.ReactElement;
  theme: any;
}

const PageWrapper = ({children, theme}: IPageWrapper) => {
  const {setTheme, setThemeOption} = usePageSetting();

  useEffect(() => {
    if (theme && theme?.__typename === 'Theme') {
      setTheme(THEME.DEFAULT);
      setThemeOption(theme);
    }
  }, []);

  return children || null;
};

export default PageWrapper;
