/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../types';

import * as TypescriptOperationsGenerated from "graphql/typescript-operations.generated"
import * as Operations from 'graphql/document-nodes.generated';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from 'withApollo';
export async function getServerPageGetGamesListingById
    (options: Omit<Apollo.QueryOptions<TypescriptOperationsGenerated.GetGamesListingByIdQueryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<TypescriptOperationsGenerated.GetGamesListingByIdQueryQuery>({ ...options, query: Operations.GetGamesListingByIdQueryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetGamesListingById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetGamesListingByIdQueryQuery, TypescriptOperationsGenerated.GetGamesListingByIdQueryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetGamesListingByIdQueryDocument, options);
};
export type PageGetGamesListingByIdComp = React.FC<{data?: TypescriptOperationsGenerated.GetGamesListingByIdQueryQuery, error?: Apollo.ApolloError}>;
export const withPageGetGamesListingById = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetGamesListingByIdQueryQuery, TypescriptOperationsGenerated.GetGamesListingByIdQueryQueryVariables>) => (WrappedComponent:PageGetGamesListingByIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetGamesListingByIdQueryDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetGamesListingById = {
      getServerPage: getServerPageGetGamesListingById,
      withPage: withPageGetGamesListingById,
      usePage: useGetGamesListingById,
    }