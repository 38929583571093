import {ssrGetSectionById} from './section/get-section-by-id-query.generated';
import {ssrGetNewsListingById} from './news-listing/news-listing-query.generated';
import {ssrGetBlogListingById} from './blog-listing/blog-listing-query.generated';
import {ssrGetListNew} from './new/get-list-new-query.generated';
import {ssrGetGamesListingById} from './games-listing/games-listing-query.generated';
import {ssrGetMasTheadCollection} from './home-page/get-mashthead.generated';
import {ssrGetExploreNewUniverse} from './home-page/get-explore-new-universe.generated';
import {ssrGetGameMediaGallery} from './home-page/get-game-media-gallery.generated';
import {ssrGetSupportFaq} from './support-faq/suport-faq-query.generated';
import {ssrGetSuggestionCard} from './home-page/get-suggestion-card.generated';
import {ssrGetGlobal} from './global/get-global.generated';
import {ssrGetSaleListingById} from './sale-listing/sale-listing-query.generated';
import {ssrGetPlatformListingById} from './platform-listing/platform-listing-query.generated';

export type EntryQueryHandlerMap<T> = {
  __typename: string;
  handler: T;
};
// store
export const SaleListingQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetSaleListingById['getServerPage']
> = {
  __typename: 'SaleEvent',
  handler: ssrGetSaleListingById['getServerPage'],
};

export const SectionQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetSectionById['getServerPage']
> = {
  __typename: 'Section',
  handler: ssrGetSectionById['getServerPage'],
};

export const NewsListingQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetNewsListingById['getServerPage']
> = {
  __typename: 'NewsListing',
  handler: ssrGetNewsListingById['getServerPage'],
};

export const BlogListingQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetBlogListingById['getServerPage']
> = {
  __typename: 'BlogListing',
  handler: ssrGetBlogListingById['getServerPage'],
};

export const NewsQueryHandlerMap: EntryQueryHandlerMap<typeof ssrGetListNew['getServerPage']> = {
  __typename: 'News',
  handler: ssrGetListNew['getServerPage'],
};

export const GamesListingQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetGamesListingById['getServerPage']
> = {
  __typename: 'GamesListing',
  handler: ssrGetGamesListingById['getServerPage'],
};

export const GetMashThead: EntryQueryHandlerMap<typeof ssrGetMasTheadCollection['getServerPage']> =
  {
    __typename: 'Mashthead',
    handler: ssrGetMasTheadCollection['getServerPage'],
  };

export const GetExploreNewUniverse: EntryQueryHandlerMap<
  typeof ssrGetExploreNewUniverse['getServerPage']
> = {
  __typename: 'ExploreNewUniverse',
  handler: ssrGetExploreNewUniverse['getServerPage'],
};

export const GetGameMediaGallery: EntryQueryHandlerMap<
  typeof ssrGetGameMediaGallery['getServerPage']
> = {
  __typename: 'MediaGallery',
  handler: ssrGetGameMediaGallery['getServerPage'],
};

export const SupportFaqQueryHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetSupportFaq['getServerPage']
> = {
  __typename: 'SupportFaq',
  handler: ssrGetSupportFaq['getServerPage'],
};

export const GetSuggestionCard: EntryQueryHandlerMap<typeof ssrGetSuggestionCard['getServerPage']> =
  {
    __typename: 'SuggestionCard',
    handler: ssrGetSuggestionCard['getServerPage'],
  };

export const GlobalQueryHandlerMap: EntryQueryHandlerMap<typeof ssrGetGlobal['getServerPage']> = {
  __typename: 'Global',
  handler: ssrGetGlobal['getServerPage'],
};

export const GetPlatformListingByIdHandlerMap: EntryQueryHandlerMap<
  typeof ssrGetPlatformListingById['getServerPage']
> = {
  __typename: 'PlatformListing',
  handler: ssrGetPlatformListingById['getServerPage'],
};

export const EntryQueryHandlerMap = [
  GlobalQueryHandlerMap,
  SectionQueryHandlerMap,
  NewsListingQueryHandlerMap,
  BlogListingQueryHandlerMap,
  NewsQueryHandlerMap,
  GamesListingQueryHandlerMap,
  GetMashThead,
  GetExploreNewUniverse,
  GetGameMediaGallery,
  SupportFaqQueryHandlerMap,
  GetSuggestionCard,
  //store
  SaleListingQueryHandlerMap,
  GetPlatformListingByIdHandlerMap,
];
