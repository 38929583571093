/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../types';

import * as TypescriptOperationsGenerated from "graphql/typescript-operations.generated"
import * as Operations from 'graphql/document-nodes.generated';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from 'withApollo';
export async function getServerPageGetNewsListingById
    (options: Omit<Apollo.QueryOptions<TypescriptOperationsGenerated.GetNewsListingByIdQueryQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<TypescriptOperationsGenerated.GetNewsListingByIdQueryQuery>({ ...options, query: Operations.GetNewsListingByIdQueryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetNewsListingById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetNewsListingByIdQueryQuery, TypescriptOperationsGenerated.GetNewsListingByIdQueryQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetNewsListingByIdQueryDocument, options);
};
export type PageGetNewsListingByIdComp = React.FC<{data?: TypescriptOperationsGenerated.GetNewsListingByIdQueryQuery, error?: Apollo.ApolloError}>;
export const withPageGetNewsListingById = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetNewsListingByIdQueryQuery, TypescriptOperationsGenerated.GetNewsListingByIdQueryQueryVariables>) => (WrappedComponent:PageGetNewsListingByIdComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetNewsListingByIdQueryDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetNewsListingById = {
      getServerPage: getServerPageGetNewsListingById,
      withPage: withPageGetNewsListingById,
      usePage: useGetNewsListingById,
    }